import React, { Fragment } from 'react'
import { serialize } from 'dom-form-serializer'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Delay from 'react-delay-render';
import Grid from '@material-ui/core/Grid';
import MediaQuery from 'react-responsive';
import Popup from '../components/Popup'

import './Form.css'

class Form extends React.Component {
  static defaultProps = {
    name: 'PostBottom',
    subject: '', // optional subject of the notification email
    action: '',
    headline: 'Quer receber conteúdos assim no seu email? Inscreva-se abaixo.',
    successMessage: 'Obrigado! Vamos tratar seu email com muito carinho.',
    errorMessage:
      'Hmmm... Algo inesperado aconteceu. :('
  }

  state = {
    alert: '',
    disabled: false,
    success: false
  }

  enableSubmit = () => {
    this.setState({
      disabled: !this.state.disabled,
    })
    this.forceUpdate()
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.disabled) return

    const form = e.target
    const data = serialize(form)
    this.setState({ disabled: true })

    addToMailchimp(data.emailAddress)
      .then(res => {
        if (res.result == 'success' || res.msg.includes('already subscribed')) {
          if (typeof window.fbq === 'function') {
            window.fbq('track', 'Lead');
          };
          localStorage.setItem('converted', 'true');
          
          return res
        } else {
          throw new Error('Network error')
        }
      })
      .then(() => {
        this.setState({
          alert: this.props.successMessage,
          disabled: false,
          success: true
        })
        form.reset()
      })
      .catch(err => {
        console.error(err)
        this.setState({
          disabled: false,
          alert: this.props.errorMessage
        })
      })
  }

  render() {
    const { name, action } = this.props

    return (
      <div>
        <MediaQuery minDeviceWidth={750}>
          <Popup enabled={!this.state.success}>
            <Grid container justify="center" alignItems="center" spacing={1}>
              <Grid item xs={5}>
                <Grid container justify="center" alignItems="center" spacing={1}>
                  <div
                    className="Form--Portrait"
                    style={{
                      backgroundImage: `url(https://ucarecdn.com/264dfdb5-a092-46ee-b5c7-87633ba618c3/-/progressive/yes/-/format/auto/-/resize/1000x/)`
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={7}>
                <Grid container justify="center" alignItems="center" spacing={1}>
                  <Fragment>
                    <form
                      name={name}
                      action={action}
                      onSubmit={this.handleSubmit}
                      data-netlify=""
                      netlify-recaptcha=""
                    >
                      {!this.state.success && (
                        <div className="Form naked">
                          <h1>
                            Perguntinha rápida...
                          </h1>
                          <p className="Form--Headline">
                            { this.props.headline }
                          </p>

                          <label className="Form--Label">
                            <input
                              className="Form--Input Form--InputText"
                              type="email"
                              placeholder="Email"
                              name="emailAddress"
                              required
                            />
                            <span>Email</span>
                          </label>
                          <input type="hidden" name="form-name" value={name} />
                          <input
                            className="Button Form--SubmitButton"
                            type="submit"
                            value="Enviar"
                            disabled={this.state.disabled}
                          />
                        </div>
                      )}
                      {this.state.alert && (
                        <div className="Form--Alert">{this.state.alert}</div>

                      )}

                    </form>
                  </Fragment>
                </Grid>
              </Grid>
            </Grid>
          </Popup>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={750 - 1}>
          <Popup enabled={!this.state.success}>
            <Fragment>
              <form
                name={name}
                action={action}
                onSubmit={this.handleSubmit}
                data-netlify=""
                netlify-recaptcha=""
              >
                {!this.state.success && (
                  <div className="Form popup">
                    <h1>
                      Perguntinha rápida...
                    </h1>
                    <p className="Form--Headline">
                      { this.props.headline }
                    </p>


                    <label className="Form--Label">
                      <input
                        className="Form--Input Form--InputText"
                        type="email"
                        placeholder="Email"
                        name="emailAddress"
                        required
                      />
                      <span>Email</span>
                    </label>
                    <input type="hidden" name="form-name" value={name} />
                    <input
                      className="Button Form--SubmitButton"
                      type="submit"
                      value="Enviar"
                      disabled={this.state.disabled}
                    />
                  </div>
                )}
                {this.state.alert && (
                  <div className="Form--Alert naked">{this.state.alert}</div>

                )}

              </form>
            </Fragment>
          </Popup>
        </MediaQuery>
      </div>

    )
  }
}

export default Delay({delay: 1500})(Form)
