import React, { Fragment } from 'react'
import { serialize } from 'dom-form-serializer'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import './Form.css'

class Form extends React.Component {
  static defaultProps = {
    name: 'PostBottom',
    subject: '', // optional subject of the notification email
    action: '',
    headline: 'Você gostou? Inscreva-se para receber conteúdos exclusivos por email.',
    successMessage: 'Obrigado! Vamos tratar seu email com muito carinho.',
    errorMessage:
      'Hmmm... Algo inesperado aconteceu. :('
  }

  state = {
    alert: '',
    disabled: false,
    success: ''
  }

  enableSubmit = () => {
    this.setState({
      disabled: !this.state.disabled,
    })
    this.forceUpdate()
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.disabled) return

    const form = e.target
    const data = serialize(form)
    this.setState({ disabled: true })

    addToMailchimp(data.emailAddress)
      .then(res => {
        if (res.result == 'success' || res.msg.includes('already subscribed')) {
          if (typeof window.fbq === 'function') {
            window.fbq('track', 'Lead');
          };
          localStorage.setItem('converted', 'true');
          
          return res
        } else {
          throw new Error('Network error')
        }
      })
      .then(() => {
        this.setState({
          alert: this.props.successMessage,
          disabled: false,
          sucess: 'true'
        })
        form.reset()
      })
      .catch(err => {
        console.error(err)
        this.setState({
          disabled: false,
          alert: this.props.errorMessage
        })
      })
  }

  render() {
    const { name, action } = this.props

    if (typeof localStorage === 'undefined' || localStorage.getItem('converted') !== 'true') {
      return (
        <Fragment>
          <form
            name={name}
            action={action}
            onSubmit={this.handleSubmit}
            data-netlify=""
            netlify-recaptcha=""
          >
            {!this.state.success && (
              <div className="Form spaced">
                <p className="Form--Headline">
                  { this.props.headline }
                </p>

                <label className="Form--Label">
                  <input
                    className="Form--Input Form--InputText"
                    type="email"
                    placeholder="Email"
                    name="emailAddress"
                    required
                  />
                  <span>Email</span>
                </label>
                <input type="hidden" name="form-name" value={name} />
                <input
                  className="Button Form--SubmitButton"
                  type="submit"
                  value="Enviar"
                  disabled={this.state.disabled}
                />
              </div>
            )}
            {this.state.alert && (
              <div className="Form--Alert">{this.state.alert}</div>

            )}

          </form>
        </Fragment>
      )
    }
    return (
      <Fragment/>
    )
  }
}

export default Form
