import React, { Component } from 'react'
import _get from 'lodash/get'
import { graphql } from 'gatsby'
import AdSense from 'react-adsense';
import reactStringReplace from 'react-string-replace'
import MediaQuery from 'react-responsive'
import { scroller, animateScroll as scroll } from 'react-scroll';
import ImageGallery from 'react-image-gallery';
import { Experiment, Variant, emitter } from '@marvelapp/react-ab-test';
import TrackVisibility from 'react-on-screen';

import Content from '../components/Content'
import NewsletterForm from '../components/NewsletterForm'
import NewsletterPopUp from '../components/NewsletterPopUp'
import ContentForm from '../components/ContentForm'
import Checklist from '../components/Checklist'
import Footer from '../components/Footer'
import RecipeGrid from '../components/RecipeGrid'
import SocialSharing from '../components/SocialSharing'
import Link from '../components/Link'

import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Fade from '@material-ui/core/Fade';
import './SinglePost.css';

var shuffle = require('shuffle-array');
const PAGE_BREAKER_STRING = "<!--pagebreak-->";
const RELOAD = 1;

// active experiments
emitter.defineVariants('SinglePage', ['Control', 'Treatment'], [100, 0]);

const InContentAd = (key) => {
  return (
    <div>
    </div>
  )
}

const BetweenContentAd = (key) => {
  return (
    <div>
    </div>
  )
}

const AfterTitleAd = (key) => {
  return (
    <div>
    </div>
  )
}

const BeforePaginationAd = (key) => {
  return (
    <div>
    </div>
  )
}

var SidebarAd = (key) =>
  <div>
    <center>
      <div data-premium="" data-adunit="MEU_CREME_BRULEE_LATERAL_01" data-sizes-desktop="[[300,600]]" >
      </div>
    </center>
  </div>

var AnchorAd = (props) =>
  <div>
    <MediaQuery maxDeviceWidth={750 - 1}>
      <AppBar position="fixed" color="primary" style={{top: 'auto', bottom: 0, backgroundColor: 'white', borderStyle: 'solid', borderWidth: '2px', borderColor: '#f3f3f3', boxShadow: '1'}}>
        <div style={{ display: 'inline-block', margin: 'auto' }}>
          <SocialSharing
            url={props.url}
            media={props.media}
          />
        </div>
      </AppBar>
    </MediaQuery>
  </div>

const PostPagination = ({ pages, currentPageIndex, currentPostURL, nextPostURL, nextPostTitle, previousPage, nextPage }) => {
  if (pages.length > 1) {
    if (currentPageIndex > 0) {
      if ((currentPageIndex+1) === pages.length) {
        return(
          // last page
          <div>
            <NewsletterForm
              name="Newsletter"
              headline="Gostou? Inscreva-se para receber conteúdos similares por email"
            />
            <BeforePaginationAd key={Math.random()} />
            <div className="SinglePost--Pagination">
              <button
                className="SinglePost--Pagination--Link prev"
                onClick={previousPage}
              >
                ⟵
              </button>
              {nextPostURL && (
                <a href={nextPostURL}>
                  <button
                    className="SinglePost--Pagination--Link next"
                  >
                    Continuar ⟶
                  </button>
                </a>
              )}
            </div>
          </div>

        )
      } else {
        return(
          // middle page
          <div>
            <BeforePaginationAd key={Math.random()} />
            <div className="SinglePost--Pagination">
              <button
                className="SinglePost--Pagination--Link prev"
                onClick={previousPage}
              >
                ⟵
              </button>
              <button
                className="SinglePost--Pagination--Link next"
                onClick={nextPage}
              >
                Continuar ⟶
              </button>
            </div>
          </div>
        )
      }
    } else {
      return(
        // first page
        <div>
          <BeforePaginationAd key={Math.random()} />
          <p>
            Clique abaixo em <b>CONTINUAR ⟶</b> e avance para a próxima página.
          </p>
          <div className="SinglePost--Pagination">
            <button
              className="SinglePost--Pagination--Link next"
              onClick={nextPage}
            >
              Continuar ⟶
            </button>
          </div>
        </div>
      )
    }
  }

  return(
    // single page
    <div>
      <BeforePaginationAd key={Math.random()} />
      <div className="SinglePost--Pagination">
        {nextPostURL && (
          <Link
            className="SinglePost--Pagination--Link post"
            to={nextPostURL}
          >
            {nextPostTitle}
            <br/>
            ⟶
          </Link>
        )}
      </div>
    </div>
  )
}

class SinglePostTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = { currentPageIndex: this.props.page };
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.restart = this.restart.bind(this);
    this.pages = this.getPages(this.props.paginate);
    this.pagesLength = this.pages.length;
    this.reload = this.props.reload;
    this.paginate = this.props.paginate;
  }

  changePage = (direction) => {
    if (this.reload || (this.state.currentPageIndex > 0 && this.state.currentPageIndex % RELOAD == 0)) {
      sessionStorage.setItem(this.props.fields.slug, this.state.currentPageIndex + direction);
      window.location.reload(true);
    } else {
      this.setState({
        currentPageIndex: this.state.currentPageIndex + direction
      })
      scroll.scrollToTop({
        duration: 0,
      })
      window.scrollTo(0, 0);
    }


  }

  previousPage = () => {
    this.changePage(-1)
  }

  nextPage = () => {
    this.changePage(+1)
  }

  restart = () => {
    this.setState({
      currentPageIndex: 0
    })
    scroller.scrollTo('scrollToElement', {
      duration: 1500,
      delay: 50,
      smooth: true,
    })
  }

  getItem = (item, index, arr) => {
    if (index && arr[index-1] == '<li>') {
      return item;
    }
  }

  getChecklist = (item, index, arr) => {
    if (index && arr[index-1] == '<!--checklist-->') {
      item = reactStringReplace(item, "<li>", (match, i) => (
        '<li>'
      ));
      item = reactStringReplace(item, "</li>", (match, i) => (
        '</li>'
      ));
      const items = item.map(this.getItem).filter(item => (typeof item !== 'undefined'));
      return <Checklist items={items}/>
    }
    return item
  }

  getGallery = (item, index, arr) => {
    if (index && arr[index-1] == '<!--gallery-->') {
      let pictures = item.match(new RegExp('src="(.*)"', 'g')).map(text => text.replace('src="', '').replace('"', ''));
      pictures = pictures.map(picture => ({
        original: `${picture}-/resize/1200x850/`,
        thumbnail: `${picture}-/resize/240x170/`,
      }));
      return <ImageGallery key={Math.random()} items={pictures} />
    }
    return item
  }

  getIndex = () => {

  }

  getPage = (page, index) => {
    let currentPage = page

    if (this.props.paginate) {
      currentPage = currentPage.trim().replace("</p>", "</p><!--incontent-->");
    } else {
      currentPage = currentPage.trim().replace("</p>", "</p><!--betweencontent-->");
    }

    currentPage = currentPage.trim().replace("</img>", "</img><!--anchor-->");

    currentPage = currentPage.replace("&#x3C;iframe", "<iframe")
    currentPage = currentPage.replace("&#x3C;/iframe>", "</iframe>")

    currentPage = reactStringReplace(currentPage, "<!--checklist-->", (match, i) => (
      '<!--checklist-->'
    ));

    currentPage = reactStringReplace(currentPage, "<!--endofchecklist-->", (match, i) => (
      '<!--endofchecklist-->'
    ));

    // if element trapped between checklist tags, grab it
    currentPage = currentPage.map(this.getChecklist);

    currentPage = reactStringReplace(currentPage, "<!--gallery-->", (match, i) => (
      '<!--gallery-->'
    ));

    currentPage = reactStringReplace(currentPage, "<!--endofgallery-->", (match, i) => (
      '<!--endofgallery-->'
    ));

    // if element trapped between checklist tags, grab it
    currentPage = currentPage.map(this.getGallery);

    currentPage = reactStringReplace(currentPage, "<!--incontent-->", (match, i) => (
      (index + 1 == 5 || index + 1 == 15) ?
      <NewsletterForm
        headline="Junte-se à comunidade do MCB e não perca as novidades."
      /> :
      <InContentAd key={Math.random()} />
    ));

    currentPage = reactStringReplace(currentPage, "<!--betweencontent-->", (match, i) => (
      <BetweenContentAd key={Math.random()} />
    ));

    currentPage = reactStringReplace(currentPage, "<!--anchor-->", (match, i) => (
      "<!--betweencontent-->"
    ));

    currentPage = reactStringReplace(currentPage, "<!--content-->", (match, i) => (
      <ContentForm
        headline="Acesse a lista de alimentos da Dieta Cetogênica com o seu email:"
      />
    ));

    return (
      <div>
        <div className="SinglePost--InnerContent">
          {index + 1 == 10 &&
            <TrackVisibility once>
                {({ isVisible }) => isVisible &&
                  <NewsletterPopUp
                    name="Newsletter"
                    headline="Quer receber conteúdos assim no seu email? Inscreva-se abaixo."
                  />}
            </TrackVisibility>
          }
          {currentPage.map(part => (typeof part === "string" ?
                                    <Content source={part.trim()}/> :
                                    part))}


        </div>
      </div>
    )
  }

  getPages = (paginate) => {
    let parts = this.props.body.split(PAGE_BREAKER_STRING);
    parts = parts.map((part, index) => this.getPage(part, index));

    if (!paginate) {
      return [<div>{parts.map((part, index) => (part))}</div>]
    };

    return parts;

  }

  componentDidMount() {
    scroll.scrollToTop({
      duration: 0,
    })

    // update google analytics
    if (typeof window.ga === 'function') {
      window.scrollTo(0, 0);

      window.ga('send', {hitType: 'event',
                         eventCategory: 'Post',
                         eventAction: this.props.title,
                         eventLabel: this.state.currentPageIndex + 1});

      window.ga('send', {hitType: 'event',
                         eventCategory: 'Experiment',
                         eventAction: 'start',
                         eventLabel: 'SinglePage' + ' / ' + emitter.getActiveVariant('SinglePage')});

    };

    // save index of current page locally
    if (sessionStorage.getItem(this.props.fields.slug) !== 'undefined') {
      this.setState({ currentPageIndex: Number(sessionStorage.getItem(this.props.fields.slug)) });
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // google analytics
    if (typeof window.ga === 'function') {

      if (!this.reload && this.state.currentPageIndex != prevState.currentPageIndex && this.state.currentPageIndex != 0) {
        window.ga('send', 'pageview', prevProps.fields.slug + (this.state.currentPageIndex > 0 ? ((this.state.currentPageIndex + 1).toString() + '/') : ''))
      };

      window.ga('send', {hitType: 'event',
                         eventCategory: 'Post',
                         eventAction: this.props.title,
                         eventLabel: this.state.currentPageIndex})

      if (this.state.currentPageIndex + 1 == this.pagesLength) {
        window.ga('send', {hitType: 'event',
                           eventCategory: 'Post',
                           eventAction: 'reachLastPage',
                           eventLabel: this.props.title})
      };
    }
    // facebook
    if (this.state.currentPageIndex + 1 == 5 && typeof window.fbq === 'function') {
      window.fbq('trackCustom', 'Engage', {slug: prevProps.fields.slug});
    };
    sessionStorage.setItem(this.props.fields.slug, this.state.currentPageIndex);
  }

  render() {
    let { title, nextPostURL, nextPostTitle, reload, posts } = this.props;
    let { currentPageIndex } = this.state;

    let currentPage = this.pages[currentPageIndex];

    return (
      <main>
        <article
          className="SinglePost section light"
          itemScope
          itemType="http://schema.org/BlogPosting"
        >

          <div className="container skinny">
            {(currentPageIndex + 1 == 1 && title) && (
              <h1 className="SinglePost--Title" itemProp="title">
                {title}
              </h1>
            )}
            <div className="SinglePost--Content relative">

              <MediaQuery minDeviceWidth={750}>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <div className="SinglePost--Sidebar">
                      <SocialSharing
                        url={this.props.fields.slug}
                        media={this.props.frontmatter.featuredImage}
                        size={42}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <div className="SinglePost--Content relative">
                      {currentPage}
                      <PostPagination pages={this.pages}
                                      currentPageIndex={currentPageIndex}
                                      currentPostURL={this.props.fields.slug}
                                      nextPostURL={nextPostURL}
                                      nextPostTitle={nextPostTitle}
                                      previousPage={this.previousPage}
                                      nextPage={this.nextPage} />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="SinglePost--Sidebar">
                      <SidebarAd key={Math.random()} />
                    </div>
                  </Grid>
                </Grid>
              </MediaQuery>
              <MediaQuery maxDeviceWidth={750 - 1}>
                {currentPage}
                <PostPagination pages={this.pages}
                                currentPageIndex={currentPageIndex}
                                currentPostURL={this.props.fields.slug}
                                nextPostURL={nextPostURL}
                                nextPostTitle={nextPostTitle}
                                previousPage={this.previousPage}
                                nextPage={this.nextPage} />
              </MediaQuery>
              <AnchorAd
                url={this.props.fields.slug}
                media={this.props.frontmatter.featuredImage}
              />
            </div>
            <MediaQuery minDeviceWidth={750}>
              <RecipeGrid title='' tiles={posts} subject='Artigos' slug='/blog' ad={[1]} />
            </MediaQuery>
            <MediaQuery maxDeviceWidth={750 - 1}>
              <div className="container">
                <RecipeGrid title='' tiles={posts} subject='Artigos' slug='/blog' ad={[1]} />
              </div>
            </MediaQuery>
          </div>
        </article>
        <Footer />
      </main>
    )
  }
}


// Export Default SinglePost for front-end
const SinglePost = ({ data: { post, allPosts, relatedPosts } }) => {
  const thisEdge = allPosts.edges.find(edge => edge.node.id === post.id)

  var relatedPos = relatedPosts.edges.filter(edge => edge.node.frontmatter.status == 'Published');
  relatedPos = relatedPos.filter(edge => edge.node.id != post.id);

  if (typeof sessionStorage !== 'undefined' && sessionStorage.getItem(post.slug) === 'undefined') {
    sessionStorage.setItem(post.slug, 0);
  };


  // REFACTOR for efficiency
  var nextPostURL = _get(thisEdge, 'next.fields.slug');
  var nextPostTitle = _get(thisEdge, 'next.frontmatter.title');

  if (post.frontmatter.categories && post.frontmatter.categories.length) {
    const cat = post.frontmatter.categories[0].category;
    const pub = Date.parse(post.frontmatter.date);

    var sameCategory = relatedPosts.edges.filter(edge => (edge.node.frontmatter.status == 'Published') && (edge.node.id != post.id) && (edge.node.frontmatter.categories) && (edge.node.frontmatter.categories[0].category === cat));

    var publishedBefore = sameCategory.filter(edge => Date.parse(edge.node.frontmatter.date) < pub);
    var publishedAfter = sameCategory.filter(edge => Date.parse(edge.node.frontmatter.date) > pub);

    if (publishedBefore.length) {
      nextPostURL = publishedBefore[0].node.fields.slug;
      nextPostTitle = publishedBefore[0].node.frontmatter.title;
    } else if (publishedAfter.length) {
      nextPostURL = publishedAfter[0].node.fields.slug;
      nextPostTitle = publishedAfter[0].node.frontmatter.title;
    }

  }

  return (

    <Experiment name="SinglePage">
      <Variant name="Control">
        {(typeof sessionStorage !== 'undefined' && sessionStorage.getItem(post.slug) !== 'undefined') && (
          <SinglePostTemplate
            {...post}
            {...post.frontmatter}
            body={post.html}
            nextPostURL={nextPostURL}
            nextPostTitle={nextPostTitle}
            posts={shuffle.pick(relatedPos, { 'picks': 4 })}
            paginate={typeof sessionStorage !== 'undefined' && sessionStorage.getItem('utm_source') !== 'undefined'}
            paid={true}
            reload={true}
            page={Number(sessionStorage.getItem(post.slug))}
          />
        )}
      </Variant>
      <Variant name="Treatment">
        {(typeof sessionStorage !== 'undefined' && sessionStorage.getItem(post.slug) !== 'undefined') && (
          <SinglePostTemplate
            {...post}
            {...post.frontmatter}
            body={post.html}
            nextPostURL={nextPostURL}
            nextPostTitle={nextPostTitle}
            posts={shuffle.pick(relatedPos, { 'picks': 4 })}
            paginate={typeof sessionStorage !== 'undefined' && sessionStorage.getItem('utm_source') !== 'undefined'}
            paid={true}
            reload={true}
            page={Number(sessionStorage.getItem(post.slug))}
          />
        )}
      </Variant>
    </Experiment>
  )
}

export default SinglePost

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SinglePost($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        template
        subtitle
        date
        categories {
          category
        }
        featuredImage
      }
      fields {
        slug
      }
    }

    allPosts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
        }
        next {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        previous {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }

    relatedPosts: allMarkdownRemark(
      filter: { fields: { contentType: { in: ["posts", "quizzes"] } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            status
            categories {
              category
            }
            tags {
              tag
            }
            featuredImage
          }
        }
      }
    }
  }
`
