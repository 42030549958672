import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: 15,
  },
  label: {
    '& > span': {
      fontSize: '15px',
    },
  },
  dot: {
    minWidth: 0,
  },
  avatar: {
    color: 'rgb(64, 224, 208, 0.7)',
    fontSize: '20px',
  }
}));

const Checklist = props => {
  const { items } = props;

  const classes = useStyles();
  const [checked, setChecked] = React.useState([0]);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <List className={classes.root}>
      {items.map(value => {
        const labelId = `checkbox-list-label-${value}`;

        if (value.startsWith(">")) {
          return (
            <ListItem key={value} >
              <ListItemText id={labelId} primary={value.replace('> ', '')} className={classes.label} >
                {value.replace('> ', '')}
              </ListItemText>
            </ListItem>
          );
        };

        return (
          <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
            <ListItemIcon className={classes.dot}>
              <Checkbox
                edge="start"
                checked={checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
                color="primary"
                icon={<RadioButtonUncheckedRoundedIcon className={classes.avatar} />}
                checkedIcon={<CheckCircleRoundedIcon className={classes.avatar} />}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={value} className={classes.label} >
              {value}
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
}

export default Checklist
