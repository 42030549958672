import React, { Component, Fragment } from 'react'
import { X } from 'react-feather'
import Fade from '@material-ui/core/Fade';

import './Popup.css'

class Popup extends Component {
  constructor(props) {
    super(props)

    if (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('showPopup') !== null) {
      this.state = { showPopup: false }
    } else if (typeof localStorage !== 'undefined' && localStorage.getItem('converted') !== null) {
      this.state = { showPopup: false }
    }
    else {
      this.state = { showPopup: true }
    }
  }

  togglePopup() {
    this.setState({
      showPopup: false
    })
    if (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('showPopup') === null) {
      sessionStorage.setItem('showPopup', 'false');
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.enabled && typeof sessionStorage !== 'undefined' && sessionStorage.getItem('showPopup') === null) {
      sessionStorage.setItem('showPopup', 'false');
    };
  }

  render() {
    const { children, enabled=true } = this.props
    return (
      <Fragment>
        {this.state.showPopup && enabled ? (
          <Fade in={true}>
            <div className="Popup-Overlay">
              <div
                className="Popup-Background"
                onClick={this.togglePopup.bind(this)}
              ></div>
              <div className="Popup-Inner">
                <X class="Popup-Close" onClick={this.togglePopup.bind(this)} />
                {children}
              </div>
            </div>
          </Fade>

        ) : null}
      </Fragment>

    )
  }
}
export default Popup
