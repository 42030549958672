import React, { Component }  from 'react';
import MediaQuery from 'react-responsive'
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton, PinterestShareButton, PocketShareButton, EmailShareButton } from 'react-share';
import { FacebookIcon, WhatsappIcon, TwitterIcon, PinterestIcon, PocketIcon, EmailIcon } from 'react-share';

import './SocialSharing.css'

class SocialSharing extends Component {
  constructor(props) {
    super(props);
  }

  track = (source) => {
    if (typeof window.ga === 'function') {
      window.ga('send', {hitType: 'event',
                         eventCategory: 'Share',
                         eventAction: 'Click',
                         eventLabel: source});
    };
    if (typeof window.fbq === 'function') {
      window.fbq('trackCustom', 'Share', {source: source});
    };
  }

  render() {
    const { url, media, size = 37, justify = '' } = this.props;
    const hostname = 'https://meucremebrulee.com.br';

    return (
      <Grid container justify={justify} className="SocialSharing--Social">
        <ButtonBase
          focusRipple
          className='SocialSharing--SocialButton'
          onClick={() => this.track('Facebook')}
        >
          <FacebookShareButton
            url={hostname + url + '?utm_source=fb&utm_medium=social&utm_campaign=share'}
          >
            <FacebookIcon size={size} />
          </FacebookShareButton>
        </ButtonBase>
        <ButtonBase
          focusRipple
          className='SocialSharing--SocialButton'
          onClick={() => this.track('WhatsApp')}
        >
          <WhatsappShareButton url={hostname + url + '?utm_source=whatsapp&utm_medium=im&utm_campaign=share'} >
            <WhatsappIcon size={size} />
          </WhatsappShareButton>
        </ButtonBase>
        <ButtonBase
          focusRipple
          className='SocialSharing--SocialButton'
          onClick={() => this.track('Twitter')}
        >
          <TwitterShareButton url={hostname + url + '?utm_source=twitter&utm_medium=social&utm_campaign=share'} >
            <TwitterIcon size={size} />
          </TwitterShareButton>
        </ButtonBase>
        <ButtonBase
          focusRipple
          className='SocialSharing--SocialButton'
          onClick={() => this.track('Pinterest')}
        >
          <PinterestShareButton url={hostname + url + '?utm_source=pinterest&utm_medium=social&utm_campaign=share'} media={media} >
            <PinterestIcon size={size} />
          </PinterestShareButton>
        </ButtonBase>
        <ButtonBase
          focusRipple
          className='SocialSharing--SocialButton'
          onClick={() => this.track('Pocket')}
        >
          <PocketShareButton url={hostname + url + '?utm_source=pocket&utm_medium=social&utm_campaign=share'} >
            <PocketIcon size={size} />
          </PocketShareButton>
        </ButtonBase>
        <ButtonBase
          focusRipple
          className='SocialSharing--SocialButton'
          onClick={() => this.track('Email')}
        >
          <EmailShareButton url={hostname + url + '?utm_source=email&utm_medium=email&utm_campaign=share'} >
            <EmailIcon size={size} />
          </EmailShareButton>
        </ButtonBase>
      </Grid>
    );
  }
}

export default SocialSharing
