import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import MediaQuery from 'react-responsive'
import PostCard from '../components/PostCard'
import PostSection from '../components/PostSection'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Link from '../components/Link'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(theme => ({
  root: {
    textDecoration: 'none',
    background: 'white',
    color: 'inherit',
    border: '1px solid var(--lightGrey)',
    borderRadius: 'var(--borderRadius)',
    boxShadow: '0 2px 15px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.15s ease-in-out',
    overflow: 'hidden',
    minHeight: 400,
    marginBottom: 50,
  },
  wrapper: {
    marginBottom: 50,
  },
  fab: {
    margin: theme.spacing(1),
    background: 'white',
    fontSize: 12,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
    marginBottom: 25
  },
  mainWrapper: {
    marginTop: 30,
  }
}));

const RecipeGrid = props => {
  const { title, tiles, subject, slug, ad = [] } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  const steps = tiles.slice(0, 5).map(tile => ({
    title: tile.node.frontmatter.title,
    excerpt: tile.node.excerpt,
    featuredImage: tile.node.frontmatter.featuredImage,
    slug: tile.node.fields.slug,
    categories: tile.node.frontmatter.categories,
    tags: tile.node.frontmatter.tags? tile.node.frontmatter.tags : [],
  }));

  const maxSteps = steps.length;

  return (
    <div className={classes.mainWrapper}>
      <h1>
        {title}
      </h1>
      <div className={classes.wrapper}>
        <PostSection posts={steps.slice(0, 4)} ad={ad} />
      </div>
      {subject && (
        <center className={classes.wrapper}>
          <Link to={slug} className={classes.link}>
            <Fab variant="extended" aria-label="delete" className={classes.fab}>
              <AddIcon className={classes.extendedIcon} />
              {subject}
            </Fab>
          </Link>
        </center>
      )}
    </div>
  );
}

export default RecipeGrid;
