import React, { Fragment } from 'react'
import { serialize } from 'dom-form-serializer'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import './Form.css'

class Form extends React.Component {
  static defaultProps = {
    name: 'PostBottom',
    subject: '', // optional subject of the notification email
    action: '',
    headline: 'Você gostou? Inscreva-se para receber conteúdos exclusivos por email.',
    successMessage: 'Obrigada! Trataremos o seu email com carinho.',
    errorMessage:
      'Hmmm... Algo inesperado aconteceu. :('
  }

  state = {
    alert: '',
    disabled: false,
    success: ''
  }

  enableSubmit = () => {
    this.setState({
      disabled: !this.state.disabled,
    })
    this.forceUpdate()
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.disabled) return

    const form = e.target
    const data = serialize(form)
    this.setState({ disabled: true })

    addToMailchimp(data.emailAddress)
      .then(res => {
        console.log(res)
        if (res.result == 'success' || res.msg.includes('already subscribed')) {
          if (typeof window.fbq === 'function') {
            window.fbq('track', 'Lead');
          };
          localStorage.setItem('converted', 'true');

          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = 'https://meucremebrulee.com.br/guides/dieta_cetogenica.pdf';
          a.download = 'Alimentos da Dieta Cetogênica';
          document.body.appendChild(a);
          a.click();
          return res
        } else {
          throw new Error('Network error')
        }
      })
      .then(() => {
        this.setState({
          alert: this.props.successMessage,
          disabled: false,
          sucess: 'true'
        })
        form.reset()

        setTimeout(function () {
          window.location.replace("/posts/investigando-o-jejum-intermitente/");
        }, 3000);
      })
      .catch(err => {
        console.error(err)
        this.setState({
          disabled: false,
          alert: this.props.errorMessage
        })
      })
  }

  render() {
    const { name, action } = this.props

    return (
      <Fragment>
        <form
          name={name}
          action={action}
          onSubmit={this.handleSubmit}
          data-netlify=""
          netlify-recaptcha=""
          target="_blank"
        >
          {!this.state.success && (
            <div className="Form spaced">
              <p className="Form--Headline">
                { this.props.headline }
              </p>

              <label className="Form--Label">
                <input
                  className="Form--Input Form--InputText"
                  type="email"
                  placeholder="Email"
                  name="emailAddress"
                  required
                />
                <span>Email</span>
              </label>
              <input type="hidden" name="form-name" value={name} />
              <input
                className="Button Form--SubmitButton"
                type="submit"
                value="Acessar"
                disabled={this.state.disabled}
              />
            </div>
          )}
          {this.state.alert && (
            <div className="Form--Alert">{this.state.alert}</div>

          )}

        </form>
      </Fragment>
    )
  }
}

export default Form
